import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

import { personReducer } from './person';
import { uiReducer } from './ui';

const rootReducer = combineReducers({
  person: personReducer,
  ui: uiReducer
});

export type AppState = ReturnType<typeof rootReducer>; // create type base on object

/// here u configure store
export const configureStore = () => {
  const middlewares = [thunkMiddleware];
  const middleWareEnhancer = applyMiddleware(...middlewares);

  const store = createStore(rootReducer, composeWithDevTools(middleWareEnhancer));

  return store;
};
