import './styles/main.scss';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import 'i18n/setup';
import App from './App';
import { configureStore } from './store';
import { IRobot, Robot } from './Robot';
/// css
import { Fabric } from '@fluentui/react/lib/Fabric';

// icons
import { initializeIcons } from '@uifabric/icons';
import { registerExtraIcons } from 'icon/registerExtraIcons';
import { loadTheme } from '@fluentui/react';

registerExtraIcons();

declare global {
  interface Window {
    Robot: IRobot;
  }
}

if (!Object.hasOwnProperty.call(window, 'Robot')) {
  window.Robot = new Robot();
}

const store = configureStore();

initializeIcons();

const appTheme = loadTheme({
  defaultFontStyle: {
    fontFamily: 'Selawik Web, Segoe UI, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif'
  }
});

function noop() {}

if (`${process.env.NODE_ENV}` !== 'development') {
  console.log = noop;
  console.warn = noop;
  console.error = noop;
}

const Root = () => {
  return (
    <Fabric className="ms-Fabric--selawik" theme={appTheme}>
      <BrowserRouter>
        <Provider store={store}>
          <App />
        </Provider>
      </BrowserRouter>
    </Fabric>
  );
};

ReactDOM.render(<Root />, document.getElementById('root'));
