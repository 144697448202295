import Axios, { AxiosInstance } from 'axios';
import { Constant } from 'model';

import {
  BaseRequest,
  LanguageDto,
  BhpStatusDto,
  QuizFormRequest,
  WizardConfigDto,
  QueryRequest,
  HashRequest
} from 'apiModel';
import { WizardOptionResponseDto, QuizSolveRequest } from 'apiModel/QuizSolveRequest';
import { HashedDataDto } from 'apiModel/HashedDataDto';
import { FormDataDto } from 'apiModel/FormDataDto';

const API_BHP_STATUS = '/api/quiz/bhpStatus';
const API_LANGUAGES = '/api/quiz/languages';
const API_QUIZ_FORM = '/api/quiz/quizBhp';
const API_SOLVE_QUIZ = '/api/quiz/solve';
const API_HASHED_DATA = '/api/quiz/hashData';

class QuizApi {
  private api: AxiosInstance;

  constructor(private baseUrl: string) {
    this.api = Axios.create({
      baseURL: `${baseUrl}`,
      headers: {},
      validateStatus: (status) => {
        return true;
      }
    });
  }

  /**
   * Get quiz languages
   * @param request
   *
   */
  public getQuizLanguages() {
    const request: BaseRequest = {
      token: `${Constant.Token}`
    };

    return this.api.post<LanguageDto[]>(API_LANGUAGES, request);
  }

  /**
   * Get BHP Status
   * @param request
   *
   */
  public getBhpStatus(hash: string) {
    const request: HashRequest = {
      token: `${Constant.Token}`,
      hash
    };

    return this.api.post<BhpStatusDto>(API_BHP_STATUS, request);
  }

  /**
   * Get Quiz Form
   * @param request
   *
   */
  public getQuizForm(lang: string) {
    const request: QuizFormRequest = {
      token: `${Constant.Token}`,
      lang: lang
    };

    return this.api.post<WizardConfigDto[]>(API_QUIZ_FORM, request);
  }

  /**
   * Solve Quiz Form
   * @param lang - jezyk quiz jesli surveyId nie utworzone w addQuiz backend doda survey
   * @param userId - id uzytkownika
   * @param surveyId - id ankiety - opcjonalne -1 / 0
   * @param
   *
   */
  public solveQuizForm(lang: string, hash: string, data: WizardOptionResponseDto[], surveyId: number = 0) {
    const request: QuizSolveRequest = {
      token: `${Constant.Token}`,
      lang: lang,
      hash: hash,
      data: [...data],
      surveyId: surveyId && surveyId > 0 ? surveyId : 0
    };

    return this.api.post<BhpStatusDto>(API_SOLVE_QUIZ, request);
  }

  public getHashedData(hash: string) {
    const request: QueryRequest = {
      token: `${Constant.Token}`,
      query: hash
    };

    return this.api.post<HashedDataDto>(API_HASHED_DATA, request);
  }

  public getHashedDataForm(hash: string) {
    const request: QueryRequest = {
      token: `${Constant.Token}`,
      query: hash
    };

    return this.api.post<FormDataDto>(API_HASHED_DATA, request);
  }
}

export const quizApi = new QuizApi(Constant.BaseUrl);
