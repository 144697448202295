import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { UserDto } from '../../apiModel';
import * as actions from './actions';

export interface PersonState {
  userId: number;
  user: UserDto | null;
  possibleUsers: UserDto[];
  searchResultMessage: string;
}

const initialState: PersonState = {
  userId: -1,
  user: null,
  possibleUsers: [],
  searchResultMessage: ''
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const devState: PersonState = {
  userId: -1,
  user: {
    id: -1
  } as UserDto,
  possibleUsers: [],
  searchResultMessage: ''
};

export const personReducer = reducerWithInitialState(initialState)
  .caseWithAction(actions.setUser, (state, action) => ({
    ...state,
    user: action.payload,
    userId: action.payload !== null ? action.payload.id : -1
  }))

  .build();
