import Axios, { AxiosInstance } from 'axios';
import { Constant } from 'model';
import { WizardConfigDto, WizardFormRequest } from 'apiModel';
import { UpdateFormRequest, UpdateFormItem } from 'apiModel/UpdateFormRequest';

const API_GET_WIZARD_FORM = '/api/wizard/getForm';
const API_UPDATE_SURVEY = '/api/wizard/save';

class WizardApi {
  private api: AxiosInstance;

  constructor(private baseUrl: string) {
    this.api = Axios.create({
      baseURL: `${baseUrl}`,
      headers: {},
      validateStatus: (status) => {
        return true;
      }
    });
  }

  /**
   * Get Wizard Form
   * @param personExternalId === hostingInstanceId
   * @param formId id of the form from which the data is taken
   *
   */
  public getWizardForm(hash: string) {
    const request: WizardFormRequest = {
      token: `${Constant.Token}`,
      hash
    };

    return this.api.post<WizardConfigDto[]>(API_GET_WIZARD_FORM, request);
  }

  public saveForm(hash: string, responses: UpdateFormItem[]) {
    const request: UpdateFormRequest = {
      token: `${Constant.Token}`,
      hash,
      responses
    };

    return this.api.post(API_UPDATE_SURVEY, request);
  }
}

export const wizardApi = new WizardApi(Constant.BaseUrl);
