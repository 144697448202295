export const resources = {
  en: {
    translation: {
      'page.welcome.enter.name': 'Enter your name',
      'page.welcome.start.test': 'Start test!',
      'page.welcome.start.back': 'Back',
      'page.welcome.search.button': 'Search',
      'page.welcome.qr.button': 'I have QR code',
      'page.welcome.watch.again': 'Watch again',
      'page.video.try.again': 'I want to try again',
      'page.video.start.again': 'Start again',
      'page.question.element.previous': 'Previous',
      'page.question.element.next': 'Next',
      'page.question.element.finish': 'Finish',
      'page.question.score': 'Your score',
      'page.score.code.valid': 'Your current BHP status is valid. Use your QR code to pass security check.',
      'page.score.bhp.error': 'Your current BHP status is failed or expired.',
      'page.score.follow.quiz': 'Please follow to Quiz.',
      'page.score.code.header': 'Complete the test',
      'page.language.select': 'Select language',
      'no.records.found.no.appointment.possible':
        'We could not found your your data in system. Please try again. If your appointment is not today it is not available to pass security check early.',
      'page.quiz.progress.question': 'Question',
      'page.score.bhp.valid.to': 'Valid to',
      'page.personal.data.submit': 'Submit',
      'page.personal.data.name': 'Name',
      'page.personal.data.surname': 'Surname',
      'page.personal.data.phone': 'Phone number',
      'page.personal.data.email': 'E-mail',
      'page.personal.data.car': 'Car registration number',
      'page.personal.data.confirm': 'I confirm that the data provided in the form are correct',
      'page.personal.data.page.basic': 'Basic data',
      'page.personal.data.form.title': 'Personal data form',
      'page.personal.data.form.message.success': 'Personal data form has been updated successfully',
      'page.personal.data.form.message.error': 'An error occurred while personal data update. Please try again',
      '#field': 'Field',
      '#cannotbeempty': 'can not be empty',
      '#shouldntbeempty': 'should not be empty',
      'invalid.phone.number': 'You must provide valid phone number',
      'summary.title': 'Here is your BHP Test summary. Review your result',
      'summary.your_answer_was_correct': 'Your answer is correct',
      'summary.your_answer_was_not_correct': 'Your answer is not correct',
      'summary.right_answer': 'Right answer'
    }
  },
  pl: {
    translation: {
      'page.welcome.enter.name': 'Podaj swoję dane',
      'page.welcome.start.test': 'Rozpocznij test!',
      'page.welcome.start.back': 'Powrót',
      'page.welcome.search.button': 'Szukaj',
      'page.welcome.qr.button': 'Posiadam kod QR',
      'page.welcome.watch.again': 'Obejrzyj ponownie',
      'page.video.try.again': 'Chcę spróbować ponownie',
      'page.video.start.again': 'Zacznij jeszcze raz',
      'page.question.element.previous': 'Poprzedni',
      'page.question.element.next': 'Następny',
      'page.question.element.finish': 'Koniec',
      'page.question.score': 'Twój wynik',
      'page.score.code.header': 'Wypełnij test',
      'page.score.code.valid': 'Twój test BHP jest ważny. Użyj swojego kodu QR, aby przejść kontrolę bezpieczeństwa.',
      'page.score.bhp.error': 'Twój test BHP jest nie zdany lub wygasł.',
      'page.score.follow.quiz': 'Proszę, przejdź do Quizu.',
      'page.language.select': 'Wybierz język',
      'no.records.found.no.appointment.possible':
        'Niepowodzenie. Nie udało się odnaleźć Twoich danych w systemie. Jeśli twoje spotkanie jest w innym terminie, nie jest możliwe przeprowadzenie testu wcześniej.',
      'page.quiz.progress.question': 'Pytanie',
      'page.score.bhp.valid.to': 'Ważny do',
      'page.personal.data.submit': 'Prześlij formularz',
      'page.personal.data.name': 'Imię',
      'page.personal.data.surname': 'Nazwisko',
      'page.personal.data.phone': 'Numer telefonu',
      'page.personal.data.email': 'E-mail',
      'page.personal.data.car': 'Numer rejestracyjny samochodu',
      'page.personal.data.confirm': 'Potwierdzam, że dane przedstawione w formularzu są prawidłowe',
      'page.personal.data.page.basic': 'Podstawowe dane',
      'page.personal.data.form.title': 'Formularz danych osobowych',
      'page.personal.data.form.message.success': 'Dane osobowe zostały zapisane',
      'page.personal.data.form.message.error':
        'Wystąpił błąd podczas aktualizacji danych osobowych. Proszę spróbuj ponownie',
      '#field': 'Pole',
      '#cannotbeempty': 'nie może być puste',
      '#shouldntbeempty': 'nie powinno być puste',
      'invalid.phone.number': 'Nieprawidłowy numer telefonu',
      'summary.title': 'Podsumowanie testu BHP. Oceń swój wynik',
      'summary.your_answer_was_correct': 'Twoja odpowiedź jest prawidłowa',
      'summary.your_answer_was_not_correct': 'Twoja odpowiedź jest nie prawidłowa',
      'summary.right_answer': 'Poprawna odpowiedź'
    }
  },
  de: {
    translation: {
      'page.welcome.enter.name': 'Gib deinen Namen ein',
      'page.welcome.start.test': 'Test starten!',
      'page.welcome.start.back': 'Zurück',
      'page.welcome.search.button': 'Suche',
      'page.welcome.qr.button': 'Ich habe QR-Code',
      'page.welcome.watch.again': 'Schau nochmal zu',
      'page.video.try.again': 'Ich möchte es noch einmal versuchen',
      'page.video.start.again': 'Nochmal beginnen',
      'page.question.element.previous': 'Bisherige',
      'page.question.element.next': 'Nächster',
      'page.question.element.finish': 'Fertig',
      'page.question.score': 'Ihr Ergebnis',
      'page.score.code.valid':
        'Ihr aktueller BHP-Status ist gültig. Verwenden Sie Ihren QR-Code, um die Sicherheitsprüfung zu bestehen.',
      'page.score.bhp.error': 'Ihr aktueller BHP-Status ist fehlgeschlagen oder abgelaufen.',
      'page.score.follow.quiz': 'Bitte folgen Sie dem Quiz.',
      'page.score.code.header': 'Schließen Sie den Test ab',
      'page.language.select': 'Sprache auswählen',
      'no.records.found.no.appointment.possible':
        'Wir konnten Ihre Daten nicht im System finden. Bitte versuche es erneut. Wenn Ihr Termin nicht heute ist, können Sie die Sicherheitsüberprüfung nicht vorzeitig bestehen.',
      'page.quiz.progress.question': 'Frage',
      'page.score.bhp.valid.to': 'Gültig bis',
      'page.personal.data.submit': 'Einreichen',
      'page.personal.data.name': 'Name',
      'page.personal.data.surname': 'Nachname',
      'page.personal.data.phone': 'Telefonnummer',
      'page.personal.data.email': 'E-Mail',
      'page.personal.data.car': 'Autokennzeichen',
      'page.personal.data.confirm': 'Ich bestätige, dass die im Formular angegebenen Daten korrekt sind',
      'page.personal.data.page.basic': 'Grunddaten',
      'page.personal.data.form.title': 'Formular für personenbezogene Daten',
      'page.personal.data.form.message.success':
        'Das Formular für personenbezogene Daten wurde erfolgreich aktualisiert',
      'page.personal.data.form.message.error':
        'Bei der Aktualisierung der persönlichen Daten ist ein Fehler aufgetreten. Bitte versuche es erneut',
      'summary.title': 'Hier ist Ihre Zusammenfassung des BHP-Tests. Überprüfen Sie Ihr Ergebnis',
      'summary.your_answer_was_correct': 'Ihre Antwort ist richtig',
      'summary.your_answer_was_not_correct': 'Ihre Antwort ist nicht korrekt',
      'summary.right_answer': 'Die richtige Antwort ist'
    }
  },
  fr: {
    translation: {
      'page.welcome.enter.name': 'Entrez votre nom',
      'page.welcome.start.test': 'Lancer le test!',
      'page.welcome.start.back': 'Retour',
      'page.welcome.search.button': 'Chercher',
      'page.welcome.qr.button': `J'ai le code QR`,
      'page.welcome.watch.again': 'Regarder à nouveau',
      'page.video.try.again': 'Je veux réessayer',
      'page.video.start.again': 'Recommencer',
      'page.question.element.previous': 'Précédente',
      'page.question.element.next': 'Prochain',
      'page.question.element.finish': 'Terminer',
      'page.question.score': 'Ton score',
      'page.score.code.valid':
        'Votre statut BHP actuel est valide. Utilisez votre code QR pour passer le contrôle de sécurité.',
      'page.score.bhp.error': 'Votre statut BHP actuel a échoué ou a expiré.',
      'page.score.follow.quiz': 'Veuillez suivre le Quiz.',
      'page.score.code.header': 'Terminez le test',
      'page.language.select': 'Choisir la langue',
      'no.records.found.no.appointment.possible': `Nous n'avons pas trouvé vos données dans le système. Veuillez réessayer. Si votre rendez-vous n'est pas aujourd'hui, il n'est pas disponible pour passer le contrôle de sécurité plus tôt.`,
      'page.quiz.progress.question': 'Question',
      'page.score.bhp.valid.to': 'Valide pour',
      'page.personal.data.submit': 'Soumettre',
      'page.personal.data.name': 'Nom',
      'page.personal.data.surname': 'Nom',
      'page.personal.data.phone': 'Numéro de téléphone',
      'page.personal.data.email': 'E-mail',
      'page.personal.data.car': `Numéro d'immatriculation de la voiture`,
      'page.personal.data.confirm': 'Je confirme que les données fournies dans le formulaire sont correctes',
      'page.personal.data.page.basic': 'Données de base',
      'page.personal.data.form.title': 'Formulaire de données personnelles',
      'page.personal.data.form.message.success': 'Le formulaire de données personnelles a été mis à jour avec succès',
      'page.personal.data.form.message.error':
        "Une erreur s'est produite lors de la mise à jour des données personnelles. Veuillez réessayer"
    }
  },
  ru: {
    translation: {
      'page.welcome.enter.name': 'Введите ваше имя',
      'page.welcome.start.test': 'Начать тест!',
      'page.welcome.start.back': 'назад',
      'page.welcome.search.button': 'Поиск',
      'page.welcome.qr.button': 'У меня есть QR-код',
      'page.welcome.watch.again': 'Смотреть снова',
      'page.video.try.again': 'Я хочу попробовать еще раз',
      'page.video.start.again': 'Начать снова',
      'page.question.element.previous': 'предыдущий',
      'page.question.element.next': 'следующий',
      'page.question.element.finish': 'Конец',
      'page.question.score': 'Твой счет',
      'page.score.code.valid':
        'Ваш текущий статус BHP действителен. Используйте свой QR-код, чтобы пройти проверку безопасности.',
      'page.score.bhp.error': 'Ваш текущий статус BHP не пройден или истек.',
      'page.score.follow.quiz': 'Пожалуйста, следуйте к викторине.',
      'page.score.code.header': 'Завершить тест',
      'page.language.select': 'Выберите язык',
      'no.records.found.no.appointment.possible':
        'Мы не смогли найти ваши данные в системе. Пожалуйста, попробуйте еще раз. Если ваша встреча назначена не сегодня, она не может пройти досрочную проверку',
      'page.quiz.progress.question': 'Вопрос',
      'page.score.bhp.valid.to': 'Действителен до',
      'page.personal.data.submit': 'Отправить',
      'page.personal.data.name': 'название',
      'page.personal.data.surname': 'Фамилия',
      'page.personal.data.phone': 'Номер телефона',
      'page.personal.data.email': 'Эл. почта',
      'page.personal.data.car': 'Регистрационный номер автомобиля',
      'page.personal.data.confirm': 'Я подтверждаю, что данные, указанные в форме, верны',
      'page.personal.data.page.basic': 'Основные данные',
      'page.personal.data.form.title': 'Форма личных данных',
      'page.personal.data.form.message.success': 'форма личных данных успешно обновлена',
      'page.personal.data.form.message.error':
        'Произошла ошибка при обновлении личных данных. Пожалуйста, попробуйте еще раз',
      'summary.title': 'Вот сводка вашего теста BHP. Просмотрите свой результат',
      'summary.your_answer_was_correct': 'Ваш ответ правильный',
      'summary.your_answer_was_not_correct': 'Ваш ответ неправильный',
      'summary.right_answer': 'Правильный ответ'
    }
  }
};
