interface IRobotActions {
  setLanguage(language: string): void;
  getLanguage(): string;
  getAvailableLanguages(): string;
  say(phrase: string): void;
  cancelSpeech(): void;
  switchActivity(): void;
  switchWaitingActivity(): void;
  getName(): string;
}

// const speechTagRegex = RegExp('\\\\[^>]*\\\\');
export interface IRobot extends IRobotActions {}
/* eslint-disable no-console */
export class Robot implements IRobot {
  _availableLanguages: string[];
  _currentLanguage: string;
  constructor() {
    this._availableLanguages = ['pl', 'en', 'ru', 'de', 'fr'];
    this._currentLanguage = 'pl';
  }
  say(phrase: string): void {
    console.log(`say: ${phrase}`);
  }
  cancelSpeech(): void {
    console.log(`cancel speech`);
  }

  getAvailableLanguages(): string {
    console.log(`get languages`);
    return this._availableLanguages.join(',');
  }

  getLanguage(): string {
    console.log('get language');
    return this._currentLanguage;
  }

  setLanguage(language: string): void {
    console.log(`setting language; ${language}`);
    this._currentLanguage = language;
  }

  switchActivity(): void {
    console.log(`switching activity`);
  }

  switchWaitingActivity(): void {
    console.log(`switching waiting activity`);
  }

  getName(): string {
    return '';
  }
}
