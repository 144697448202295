import React from 'react';
import { registerIcons } from '@uifabric/styling';
import 'flag-icon-css/css/flag-icon.min.css';
/// https://github.com/lipis/flag-icon-css

export const registerExtraIcons = () => {
  registerIcons({
    icons: {
      FlagPL: <i className="flag-icon flag-icon-pl" />,
      FlagEN: <i className="flag-icon flag-icon-gb" />,
      FlagDE: <i className="flag-icon flag-icon-de" />,
      FlagFR: <i className="flag-icon flag-icon-fr" />,
      FlagRU: <i className="flag-icon flag-icon-ru" />
    }
  });
};
