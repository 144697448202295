import { reducerWithInitialState } from 'typescript-fsa-reducers';
import * as actions from './actions';
import i18n from 'i18n/setup';
import { ViewType } from 'model';

export interface UiState {
  activeView: string;
  lang: string;
  languages: string[];
}

const initialState: UiState = {
  activeView: ViewType.Quiz,
  lang: 'pl',
  languages: []
};

// eslint-disable-next-line  @typescript-eslint/no-unused-vars
const devState: UiState = {
  activeView: ViewType.Quiz,
  lang: 'pl',
  languages: ['en', 'pl']
};

export const uiReducer = reducerWithInitialState(initialState)
  .caseWithAction(actions.changeActiveView, (state, action) => ({
    ...state,
    activeView: action.payload.activeView
  }))
  .caseWithAction(actions.setLanguages, (state, action) => ({
    ...state,
    languages: [...action.payload.codes]
  }))
  .caseWithAction(actions.changeActiveLanguage, (state, action) => {
    i18n.changeLanguage(action.payload.code);

    return {
      ...state,
      lang: action.payload.code
    };
  })
  .build();
