import 'App.scss';

import React from 'react';
import { connect, MapStateToProps, MapDispatchToProps } from 'react-redux';

import { ViewType } from 'model';
import { AppState } from 'store';
import { changeActiveLanguage } from 'store/ui/actions';
import { UserDto } from 'apiModel';
import { setUser } from 'store/person';
import { Switch, Route } from 'react-router';
import QS from 'qs';
import { quizApi } from 'api';

const PersonalDataConfirmation = React.lazy(() => import('pages/PersonalDataConfirmation/PersonalDataConfirmation'));
const PageNotFound = React.lazy(() => import('components/PageNotFound/PageNotFound'));
const Quiz = React.lazy(() => import('pages/Quiz/Quiz'));

interface StateProps {
  activeView: string;
}

interface DispatchProps {
  changeLanguage: (code: string) => void;
  setUser: (userId: UserDto) => void;
}

type Props = StateProps & DispatchProps;

interface State {}

class App extends React.Component<Props, State> {
  static mapStateToProps: MapStateToProps<StateProps, {}, AppState> = ({ ui }: AppState) => {
    return {
      activeView: ui.activeView
    };
  };

  static mapDispatchToProps: MapDispatchToProps<DispatchProps, {}> = (dispatch) => ({
    changeLanguage: (code) => dispatch(changeActiveLanguage({ code })),
    setUser: (userId) => dispatch(setUser(userId))
  });

  componentDidMount() {
    this.initSetup();
  }

  initSetup = async () => {
    if (window.location.pathname.indexOf('form') > 0) {

    // ? Example path: http://localhost:9000/form?lang=en&hash=3378F153-DB99-4553-8B39-3D770790CF94

      const urlData = (QS.parse(window.location.search.substr(1)) as unknown) as { lang: string; hash: string };
      this.props.changeLanguage(urlData.lang);
      return;
    }

    if (window.location.search) {
      const urlData = (QS.parse(window.location.search.substr(1)) as unknown) as { lang: string; hash: string };

      // ? Example path: http://localhost:9000/?lang=en&hash=3378F153-DB99-4553-8B39-3D770790CF94

      if (urlData.lang) {
        window.Robot.setLanguage(urlData.lang);
        this.props.changeLanguage(urlData.lang);
      }
      if (urlData.hash) {
        const { data } = await quizApi.getHashedData(urlData.hash);

        if (data && data.userId) {
          this.props.setUser({ id: data.userId });

          return;
        }
      }
    }

    if (`${process.env.NODE_ENV}`.trim() === 'production') {
      window.location.replace(`${process.env.REACT_APP_INVALID_URL_REDIRECT}`);
    }
  };

  renderActiveView(): JSX.Element {
    const { activeView } = this.props;

    switch (activeView) {
      case ViewType.Quiz:
        return <Quiz />;
      case ViewType.PersonalDataConfirmation:
        return <PersonalDataConfirmation />;
      default:
    }

    return <PageNotFound pageName={activeView} />;
  }

  renderActiveRoute() {
    return (
      <Switch>
        <Route path="/form">
          <PersonalDataConfirmation />
        </Route>
        <Route path="/">
          <Quiz />
        </Route>
      </Switch>
    );
  }

  renderFallback(): JSX.Element {
    return <p className="app-loading">...</p>;
  }

  render() {
    return (
      <div className="app-layout">
        <React.Suspense fallback={this.renderFallback()}>{this.renderActiveRoute()}</React.Suspense>
      </div>
    );
  }
}

export default connect<StateProps, DispatchProps, {}, AppState>(App.mapStateToProps, App.mapDispatchToProps)(App);
